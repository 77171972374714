<template>
  <div class="keywords-input-selector">
    <label for="groups">{{ label }}</label>
    <multiselect
        id="groups"
        :value="dataValue"
        @input="onInput"
        :options="dataOptions"
        :multiple="false"
        :close-on-select="true"
        :clear-on-select="false"
        :searchable="false"
        :show-labels="false"
        :disabled="isDisabled"
        placeholder="Bitte auswählen..."
        autocomplete="off"
    >
      <template v-slot:option="props">
        <CIcon :name="props.option.icon" />
        {{ props.option.title }}
      </template>
      <template v-slot:singleLabel="props">
        <CIcon :name="props.option.icon" />
        {{ props.option.title }}
      </template>

    </multiselect>
    <slot name="description">
      <small class="form-text text-muted w-100 mb-3">{{ description }}</small>
    </slot>
  </div>
</template>

<script>
import axios from "axios";
import commons from "@/commons";

export default {
  name: "SocialMediaSelector",
  props: {
    label: {
      type: String,
      default: 'Plattformen auswählen:'
    },
    value: {
      type: Object,
      required: false
    },
    options: {
      type: Array,
      default: () => []
    },
    description: {
      type: String,
      default: ''
    }
  },
  computed: {
  isDisabled() {
    return this.dataOptions.length === 0;
  }
},
  data() {
    return {
      dataOptions: [...this.options],
      connections: [],
      loading: false,
      dataValue: this.value || []
    }
  },
  mounted() {
    this.loadSocialMediaConnections()
  },
  methods: {
    addTag (newTag) {
      const tag = {
        name: newTag
      }
      this.dataOptions.push(tag)
      this.value.push(tag)
    },
    onInput($event) {
      console.log($event)
      this.dataValue = $event
      this.$emit('input', $event)
    },
    loadSocialMediaConnections() {
      axios.get('/socialMedia/connections')
          .then(response => {
            let content = {}
            for (const [key, value] of Object.entries(response.data)) {
              content[key] = commons.flatObject(value)
            }
            this.connections = content
            this.loading = false
            const options = []
            if (this.connections.FACEBOOK) {
              options.push({ title: this.connections.FACEBOOK.name, icon: 'cib-facebook', id: this.connections.FACEBOOK.id })
            }
            // LinkedIn-Option hinzufügen
            if (this.connections.LINKEDIN) {
              options.push({ title: this.connections.LINKEDIN.name, icon: 'cib-linkedin', id: this.connections.LINKEDIN.id })
            }
            this.dataOptions = options
            console.log('connections', content, 'options', options)
            //this.getFBData(content.FACEBOOK.accessToken)
          })
          .catch(err => console.error('Problem while fetching social media connections', err))
          .finally(() => this.loading = false)
    },loadSocialMediaConnections() {
      axios.get('/socialMedia/connections')
          .then(response => {
            let content = {}
            for (const [key, value] of Object.entries(response.data)) {
              content[key] = commons.flatObject(value)
            }
            this.connections = content
            this.loading = false
            const options = []
            if (this.connections.FACEBOOK) {
              options.push({ title: this.connections.FACEBOOK.name, icon: 'cib-facebook', id: this.connections.FACEBOOK.id })
            }
            this.dataOptions = options
            console.log('connections', content, 'options', options)
            //this.getFBData(content.FACEBOOK.accessToken)
          })
          .catch(err => console.error('Problem while fetching social media connections', err))
          .finally(() => this.loading = false)
    },
  }
}
</script>

<style scoped>

</style>
