<template>
    <div v-if="isVisible" class="modal-overlay">
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header">
            <h3 class="modal-title">Titel</h3>
          </slot>
          <button class="close-button" @click="close">×</button>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SimpleModal',
    props: {
      isVisible: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      close() {
        this.$emit('close');
      }
    }
  }
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
  }
  
  .modal-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    max-height: 80vh;
    position: relative;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .modal-title {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .modal-body {
    margin-bottom: 10px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color: #000;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .close-button:focus {
    outline: none;
  }
  
  .close-button:hover {
    color: #ff0000; /* Roter Hover-Effekt für das Schließen-Symbol */
    background: none; /* Keine Hintergrundänderung */
  }
  
  .text-right {
    text-align: right;
  }
  
  button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  button.secondary {
    background-color: #6c757d;
    color: white;
  }
  
  button.secondary:hover {
    background-color: #5a6268;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .form-row > div {
    flex: 1;
  }
  
  .button-group {
    display: flex;
    gap: 10px;
  }
  </style>
  