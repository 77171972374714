<template>
  <SimpleModal :isVisible="show" @close="close">
    <template #header>
      <h3>KI-Content Generator</h3>
    </template>
    <template #body>
      <div class="form-group">
        <label>Überschrift</label>
        <input v-model="ai.headline" class="form-control" placeholder="Geben Sie die Überschrift Ihres Beitrags ein" />
      </div>
      <div class="form-group">
        <label>Haupttext</label>
        <textarea v-model="ai.mainText" rows="4" class="form-control" placeholder="Teilen Sie die Details Ihres Themas mit einer persönlichen Note..."></textarea>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label>Call to Action</label>
          <input v-model="ai.cta" class="form-control" placeholder="Besuchen Sie unsere Website!" />
        </div>
        <div class="form-group">
          <label>Tonwahl</label>
          <select v-model="ai.voiceStyle" class="form-control">
            <option value="ENERGETIC">Energisch</option>
            <option value="EXCITING">Aufregend</option>
            <option value="ENTHUSIASTIC">Enthusiastisch</option>
            <option value="FRIENDLY">Freundlich</option>
            <option value="INFORMATIONAL">Informativ</option>
            <option value="FUNNY">Lustig</option>
            <option value="PROFESSIONAL">Professionell</option>
            <option value="CASUAL">Lässig</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label>Zusätzliche Informationen</label>
        <textarea v-model="ai.additionalInfo" rows="4" class="form-control" placeholder="Ergänzen Sie hier weitere Details wie besondere Angebote, Kontaktinformationen oder Veranstaltungshinweise..."></textarea>
      </div>
      <div class="form-group">
        <label>Generierter Inhalt</label>
        <textarea v-model="generatedContent" rows="4" class="form-control" readonly placeholder="Hier wird der generierte Inhalt angezeigt..."></textarea>
      </div>
      <div class="text-right">
        <button @click="generate" class="btn btn-primary" :disabled="isLoading">Inhalt generieren</button>
      </div>
      <div v-if="isLoading" class="loading-indicator">
        <spinner></spinner> <!-- Falls Sie einen Spinner-Komponente haben -->
        <p>Inhalt wird generiert...</p>
      </div>
    </template>
    <template #footer>
      <div class="button-group">
        <button @click="close" class="btn btn-secondary" :disabled="isLoading">Schließen</button>
        <button @click="applyChanges" class="btn btn-primary" :disabled="isLoading">Übernehmen</button>
      </div>
    </template>
  </SimpleModal>
</template>

<script>
import axios from 'axios';
import SimpleModal from '@/views/base/social-media/SimpleModal.vue';

export default {
  name: 'KIPopup',
  components: { SimpleModal },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ai: {
        keywords: '',
        headline: '',
        mainText: '',
        cta: '',
        voiceStyle: 'ENERGETIC',
        additionalInfo: ''
      },
      generatedContent: '',
      isLoading: false
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    generate() {
      this.isLoading = true;
      const data = {
        useEmojis: true,
        voiceStyle: this.ai.voiceStyle,
        keywords: ["Beispiel", "Schlüsselwörter"],
        title: this.ai.headline,
        mainContent: this.ai.mainText,
        notes: this.ai.additionalInfo,
        cta: this.ai.cta
      };

      axios.post('/socialMedia/generateByAi', data)
        .then(response => {
          this.generatedContent = response.data.result || 'Der KI-generierte Inhalt konnte nicht abgerufen werden.';
        })
        .catch(err => {
          this.generatedContent = 'Fehler beim Generieren des Inhalts: ' + err.response.data.message;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    applyChanges() {
      this.$emit('apply', this.generatedContent);
    }
  }
}
</script>

<style scoped>
.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 4px 10px 4px 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.text-right {
  text-align: right;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  background-color: #007bff;
  color: white;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.button-group {
  display: flex;
  gap: 10px;
}

.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.loading-indicator p {
  margin-left: 10px;
  color: #007bff;
}
</style>