<template>
  <div class="form-group form-row" :key="name">
    <CCol tag="label" :sm="horizontal ? 3 : 12" class="col-form-label">
      {{ label }}
      <!--<small
          v-if="description && !horizontal"
          class="form-text text-muted w-100"
          v-text="description"
      />-->
    </CCol>
    <CCol :sm="horizontal ? 9 : 12">
      <v-date-picker :attributes="datePickerAttrs" mode="dateTime" is24hr :value="value" @input="onPicked">
        <template #default="{ inputValue, togglePopover, hidePopover }">
          <CInput
              :description="description"
              v-model="inputValue"
              :readonly="true"
              :horizontal="false"
              addInputClasses="prt-date-time-picker-input"
              @click.stop="togglePopover"
              custom>
            <template v-slot:append>
              <CButton type="button" color="warning" @click="togglePopover"><CIcon name="cil-calendar"/></CButton>
            </template>
          </CInput>
        </template>
      </v-date-picker>
      <small
              v-if="description && horizontal"
              class="form-text text-muted w-100"
              v-text="description"
      />
    </CCol>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: 'DateTimePicker',
  props: {
    label: String,
    name: String,
    description: String,
    disabled: [Boolean, String],
    horizontal: {
      type: Boolean,
      default: true
    },
    value: {
      type: [String],
      default: moment().toISOString()
    }
  },
  data () {
    return {
      datePickerAttrs: [
        {
          dot: true,
          dates: [
            new Date()
          ],
        },
      ]
    }
  },
  methods: {
    onPicked(event) {
      this.$emit('input', moment(event).toISOString())
    }
  }
}
</script>

<style>
.prt-date-time-picker-input {
  height: initial !important;
  background-color: #fff !important;
}
</style>
